import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboards',
    name: 'Dashboard',
    type: 'link',
    icon: 'av_timer'
  },

  {
    state: 'master',
    name: 'Master',
    type: 'sub',
    icon: 'location_city',
    badge: [{ type: 'red', value: '12' }],
    children: [
      { state: 'city', name: 'City', type: 'link' },
      { state: 'pincode', name: 'Pincode', type: 'link' },
      { state: 'area-master', name: 'Area', type: 'link' },
      { state: 'delivery-boys', name: 'Delivery Boys', type: 'link' },
      { state: 'users', name: 'Users', type: 'link' },
      { state: 'customer-group', name: 'Customer Group', type: 'link' },
      { state: 'product-category', name: 'Product Category', type: 'link' },
      { state: 'uom', name: 'UOM', type: 'link' },
      { state: 'hsn-code', name: 'HSN Code', type: 'link' },
      { state: 'delivery-pattern', name: 'Delivery Patterns', type: 'link' },
      // { state: 'farm', name: 'Farm', type: 'link' },
      // { state: 'central-warehouse', name: 'Central Warehouse', type: 'link' },
      { state: 'tax-rate', name: 'Tax Rate', type: 'link' },
      { state: 'vehicle', name: 'Vehicle', type: 'link' }

    ]
  },
  {
    state: 'farm',
    name: 'Farm',
    type: 'link',
    icon: 'store',
  },
  {
    state: 'central-warehouse',
    name: 'CWH',
    type: 'link',
    icon: 'home',
    // badge: [{ type: 'red', value: '1' }],
    // children: [
    //   { state: 'list', name: 'CWH List', type: 'link' }
    // ]
  },
  {
    state: 'hub',
    name: 'Hub',
    type: 'sub',
    icon: 'desktop_windows',
    badge: [{ type: 'red', value: '2' }],
    children: [
      { state: 'list', name: 'Hub List', type: 'link' },
      { state: 'delivery-logs', name: 'Delivery Logs', type: 'link' },
      { state: 'collection-summary', name: 'Collection Summary', type: 'link' }
    ]
  },
  {
    state: 'customers',
    name: 'Customers',
    type: 'sub',
    icon: 'people',
    badge: [{ type: 'red', value: '3' }],
    children: [
      { state: 'list', name: 'Customer List', type: 'link' },
      { state: 'wallet', name: 'Wallet Details', type: 'link' },
      { state: 'bottle-management', name: 'Bottle Management', type: 'link' }
    ]
  },
  {
    state: 'product',
    name: 'Products',
    type: 'link',
    icon: 'local_mall',
    // badge: [{ type: 'red', value: '2' }],
    // children: [
    //   { state: 'productlist', name: 'Product List', type: 'link' },
    //   { state: 'createproduct', name: 'Create Product', type: 'link' },
    // ]
  },
  // {
  //   state: 'authentication',
  //   name: 'Authentication',
  //   type: 'sub',
  //   icon: 'perm_contact_calendar',
  //   children: [
  //     { state: 'login', name: 'Login', type: 'link' },
  //     { state: 'register', name: 'Register', type: 'link' },
  //     { state: 'forgot', name: 'Forgot', type: 'link' },
  //     { state: 'lockscreen', name: 'Lockscreen', type: 'link' },
  //     { state: '404', name: 'Error', type: 'link' }
  //   ]
  // },
  {
    state: 'subscription',
    name: 'Subscription',
    type: 'sub',
    icon: 'subscriptions',
    badge: [{ type: 'red', value: '1' }],
    children: [
      { state: 'config', name: 'Subscription Config', type: 'link' },
      // { state: 'subscribers', name: 'Subscribers', type: 'link' },
    ]
  },
  {
    state: 'orders',
    name: 'Orders',
    type: 'sub',
    icon: 'shopping_cart',
    badge: [{ type: 'red', value: '3' }],
    children: [
      { state: 'cart', name: 'Cart Orders', type: 'link' },
      { state: 'subscription', name: 'Subscription Orders', type: 'link' }
      // { state: 'delivery-logs', name: 'Delivery Logs', type: 'link' }
    ]
  },
  {
    state: 'rolesPermission',
    name: 'Roles & Permissions',
    type: 'link',
    icon: 'lock'
  },
  {
    state: 'inventory',
    name: 'Inventory',
    type: 'sub',
    icon: 'assignment',
    badge: [{ type: 'red', value: '6' }],
    children: [
      { state: 'indent', name: 'Indent', type: 'link' },
      { state: 'inward', name: 'Inward', type: 'link' },
      { state: 'outward', name: 'Outward', type: 'link' },
      { state: 'return-stock', name: 'Return Stock', type: 'link' },
      { state: 'stock-summary', name: 'Stock Summary', type: 'link' },
      { state: 'stock-transfered', name: 'Stock Transferred', type: 'link' }

    ]
  },
  {
    state: 'accounts',
    name: 'Accounts',
    type: 'sub',
    icon: 'account_balance',
    badge: [{ type: 'red', value: '2' }],
    children: [
      { state: 'invoices', name: 'Invoices', type: 'link' },
      { state: 'payment-transactions', name: 'Payment Transactions', type: 'link' },
      { state: 'collection-summary', name: 'Collection Summary', type: 'link' },
      { state: 'pending-transaction', name: 'Pending Transaction', type: 'link' }
    ]
  },
  {
    state: 'others',
    name: 'Other',
    type: 'sub',
    icon: 'apps',
    badge: [{ type: 'red', value: '4' }],
    children: [
      { state: 'tickets', name: 'Tickets', type: 'link' },
      { state: 'company-details', name: 'Company Details', type: 'link' },
      { state: 'pincode-survey', name: 'Pincode Survey', type: 'link' },
      { state: 'terms-condition', name: 'Terms & Conditions', type: 'link' },
      { state: 'html-section', name: 'HTML Section', type: 'link' }
    ]
  },
  {
    state: 'offers',
    name: 'Offers',
    type: 'link',
    icon: 'card_giftcard'
  },
];

export const BREADSCRUM_LIST = [
  {
    path: './dashboards',
    name: 'Dashboard',
    children: []
  },
  {
    path: './master',
    name: 'Master',
    children: [
      { path: './city', name: 'City', children: [] },
      { path: './pincode', name: 'Pincode', children: [] },
      { path: './area-master', name: 'Area', children: [] },
      { path: './product-category', name: 'Product Category', children: [] },
      { path: './uom', name: 'UOM', children: [] },
      { path: './delivery-pattern', name: 'Delivery Patterns', children: [] },
      // {
      //   path: './farm', name: 'Farm', children: [
      //     { path: './create', name: 'Create Farm', children: [] },
      //     { path: './create?', name: 'Update Farm', children: [] },
      //   ]
      // },
      // {
      //   path: './central-warehouse', name: 'Central Warehouse', children: [
      //     { path: './create', name: 'Create Central Warehouse', children: [] },
      //     { path: './create?', name: 'Update Central Warehouse', children: [] },
      //   ]
      // },
      {
        path: './delivery-boys', name: 'Delivery Boys', children: [
          { path: './create', name: 'Create Delivery Person', children: [] },
          { path: './create?', name: 'Update Delivery Person', children: [] },
          { path: './detail?', name: 'Delivery Person Detail', children: [] },
        ]
      },
      {
        path: './users', name: 'Users', children: [
          { path: './create', name: 'Create User', children: [] },
          { path: './create?', name: 'Update User', children: [] },
        ]
      },
      { path: './tax-rate', name: 'Tax Rate', children: [] },
      { path: './hsn-code', name: 'HSN Code', children: [] },
      { path: './vehicle', name: 'Vehicle Master', children: [] },
      { path: './customer-group', name: 'Customer Group', children: [] }

    ]
  },
  {
    path: './farm',
    name: 'Farm',
    children: [
      { path: './list', name: 'Farm List', children: [] },
      { path: './create', name: 'Create Farm', children: [] },
      { path: './create?', name: 'Update Farm', children: [] },
    ]
  },
  {
    path: './central-warehouse',
    name: 'CWH',
    children: [
      { path: './list', name: 'CWH List', children: [] },
      { path: './detail?', name: 'CWH Detail', children: [] },
      { path: './create', name: 'Create Central Warehouse', children: [] },
      { path: './create?', name: 'Update Central Warehouse', children: [] },
    ]
  },
  {
    path: './hub',
    name: 'Hub',
    children: [
      {
        path: './list', name: 'Hub List',
        children: [
          { path: './detail?', name: 'Hub Detail', children: [] },
          { path: './create', name: 'Create Hub', children: [] },
          { path: './route', name: 'Delivery Route', children: [] },
          { path: './route?', name: 'Delivery Route', children: [] },
          { path: './create?', name: 'Update Hub', children: [] },
        ]
      },
      { path: './collection-summary', name: 'Collection Summary', children: [] },
      { path: './delivery-logs', name: 'Delivery Logs', children: [] },




    ]
  },
  {
    path: './customers',
    name: 'Customers',
    children: [
      {
        path: './list', name: 'Customer list',
        children: [
          { path: './detail?', name: 'Customer Detail', children: [] },
          { path: './create', name: 'Create Customer', children: [] },
          { path: './create?', name: 'Update Customer', children: [] },
        ]
      },
      {
        path: './wallet', name: 'Wallet Details',
        children: [
          { path: './recharge-history?', name: 'Recharge History', children: [] },
        ]
      },
      { path: './bottle-management', name: 'Bottle Management', children: [] }
    ]
  },
  {
    path: './product',
    name: 'Products', children: [
      { path: './createproduct', name: 'Create Product', children: [] },
      { path: './createproduct?', name: 'Update Product', children: [] },
    ]
  },
  {
    path: './subscription',
    name: 'Subscription',
    children: [
      { path: './config', name: 'Subscription Config', children: [] },
      { path: './subscribers', name: 'Subscribers', children: [] },
    ]
  },
  {
    path: './orders',
    name: 'Orders',
    children: [
      {
        path: './cart', name: 'Cart Orders',
        children: [
          { path: './cart-detail', name: 'Cart Order Details', children: [] },
          { path: './cart-detail?', name: 'Cart Order Details', children: [] },
        ]
      },
      {
        path: './subscription', name: 'Subscription Orders',
        children: [
          { path: './subscription-detail?', name: 'Subscription Order Details', children: [] },
          { path: './create-subscription-order', name: 'Create Subscription Orders', children: [] },
          { path: './edit-subscription-order?', name: 'Edit Subscription Orders', children: [] }
        ]
      },

      { path: './delivery-logs', name: 'Delivery Logs', children: [] },

    ]
  },
  {
    path: './rolesPermission',
    name: 'Roles & Permissions',
    children: [
      { path: './user-group', name: 'User Group', children: [] },
      { path: './permission', name: 'Permission', children: [] },
    ]
  },
  {
    path: './inventory',
    name: 'Inventory',
    children: [
      { path: './indent', name: 'Indent', children: [] },
      { path: './outward', name: 'Outward', children: [] },
      { path: './outward?', name: 'Outward', children: [] },
      { path: './return-stock', name: 'Return Stock', children: [] },
      { path: './stock-summary', name: 'Stock Summary', children: [] },
      { path: './stock-transfered', name: 'Stock Transferred', children: [] },
      { path: './inward', name: 'Inward', children: [] },
    ]
  },
  {
    path: './accounts',
    name: 'Accounts',
    children: [
      { path: './invoices', name: 'Invoices', children: [] },
      { path: './collection-summary', name: 'Collection Summary', children: [] },
      { path: './payment-transactions', name: 'Payment Transactions', children: [] },
      { path: './pending-transaction', name: 'Pending Transaction', children: [] },
    ]
  },
  {
    path: './others',
    name: 'Other',
    children: [
      { path: './tickets', name: 'Ticket', children: [] },
      { path: './company-details', name: 'Company Details', children: [] },
      { path: './pincode-survey', name: 'Pincode Survey', children: [] },
      { path: './terms-condition', name: 'Terms & Conditions', children: [] },
      { path: './html-section', name: 'HTML Section', children: [] }
    ]
  },
  // { path: './task-allocation', name: 'Task Allocation', children: [] },
  {
    path: './offers',
    name: 'Offers',
    children: []
  },
  {
    path: './task-allocation',
    name: 'Task Allocation',
    children: []
  },
  {
    path: './settings',
    name: 'Scheduler Settings',
    children: []
  }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
