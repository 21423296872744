import { environment } from './../../environments/environment.prod';
import { TillDateActivity, TodayActivity } from '../models/Dashboard';
export class MilkmorConstants {

  // pagination constants
  public static readonly PAGE_SIZE_OPTIONS: number[] = [5, 10, 25, 100];
  public static readonly DEFAULT_PAGE_SIZE: number = 10;

  // list of Tax Types
  public static readonly TAX_TYPE_LIST: any[] = [{ taxTypeValue: 'CGST,SGST', taxTypeName: 'SGST & CGST' }, { taxTypeValue: 'IGST', taxTypeName: 'IGST' }, { taxTypeValue: 'NOTAX', taxTypeName: 'No Tax' }, { taxTypeValue: 'NA', taxTypeName: 'NA' }];

  // validation regex constant
  public static readonly ALPHA_NUMERIC_WITH_PERCENTAGE_SPACE: string = '^[a-zA-Z0-9% ]*$';
  public static readonly ALPHA_NUMERIC: RegExp = new RegExp(/^(?!\s*$)[a-zA-Z0-9\s]*$/);
  public static readonly ONLY_NUMERIC: string = '^(0|[1-9][0-9]*)$';
  public static readonly ONLY_NUMERIC_HSN: string = '^(0|[0-9][0-9]*)$';
  public static readonly ONLY_ALPHA: RegExp = new RegExp(/^(?!\s*$)[a-zA-Z\s]*$/);
  public static readonly DECIMAL: string = '^[0-9][0-9]*[.]*([0-9]*)*$';
  public static readonly EMAIL: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  public static readonly ADDRESS_REGEX: RegExp = new RegExp(/^[a-zA-Z0-9,.-\s]*$/);

  // MAP DEFAULT LOCATION
  public static readonly DEFAULT_LATITUDE = 22.746581;
  public static readonly DEFAULT_LONGITUDE = 71.686925;
  public static readonly DEFAULT_ZOOM = 8;

  // Subscription Order status
  public static readonly SUBSCRIPTION_ORDER_STATUS = [{ NEW: 'New', status: 'New', index: 0 }, { ACTIVE: 'Active', status: 'Active', index: 1 }, { RENEWAL: 'Renewal', status: 'Renewal', index: 2 }, { EXPIRED: 'Expired', status: 'Expired', index: 3 }, { PAUSED: 'Paused', status: 'Paused', index: 4 }, { CANCELLED: 'Cancelled', status: 'Cancelled', index: 5 }, { UPCOMING: 'Upcoming', status: 'Upcoming', index: 6 }];

  public static readonly SUBSCRIPTION_ORDER_STATUSES = { NEW: 'New', ACTIVE: 'Active', RENEWAL: 'Renewal', EXPIRED: 'Expired', PAUSED: 'Paused', CANCELLED: 'Cancelled', UPCOMING: 'Upcoming' };

  public static readonly PERMISSIONS = { ADD: 'add', VIEW: 'view', IMPORT: 'import', EXPORT: 'export', DELETE: 'delete', EDIT: 'edit' };

  public static readonly CART_ORDER_STATUSES = { PENDING: 'Pending', IN_PROCESS: 'In Process', ATTEMPTED: 'Attempted', CANCELLED: 'Cancelled', DELIVERED: 'Delivered', REQUEST_PENDING: 'Request Pending', RETURNED: 'Returned' };

  public static readonly CART_ORDER_STATUS = [{ PENDING: 'Pending', status: 'Pending', index: 0 }, { IN_PROCESS: 'In Process', status: 'In Process', index: 1 }, { ATTEMPTED: 'Attempted', status: 'Attempted', index: 2 }, { DELIVERED: 'Delivered', status: 'Delivered', index: 3 }, { CANCELLED: 'Cancelled', status: 'Cancelled', index: 4 }, { REQUEST_PENDING: 'Request Pending', status: 'Request Pending', index: 5 }];

  public static readonly SHARE_ORDER_TYPE = { TEMPORARY: 'Temporary', PERMANENTLY: 'Permanently' };

  public static readonly TICKET_STATUS = { PENDING: 'Pending', RESOLVED: 'Resolved', ACKNOWLEDGE: 'Acknowledge' };

  public static readonly PRODUCT_TYPE = { SCHEDULED: 'SCHEDULED', NONSCHEDULED: 'NONSCHEDULED' };

  public static readonly ORDER_TYPE = { SUBSCRIPTION: 'SUBSCRIPTION', CART: 'CART' };

  public static readonly TASK_TYPE_INDIRECT = [{ NEW_USER_KYC: 'New User-KYC' }, { NEW_USER_KYC_AND_CASH_COLLECTION: 'New User-KYC & Cash Collection' }, { EXISTING_USER_KYC: 'Existing User-KYC' }, {
    EXISTING_USER_KYC_AND_CASH_COLLECTION: 'Existing User-KYC & Cash Collection'
  },
  { EXISTING_USER_CASH_COLLECTION: 'Existing User Cash Collection' }];

  public static readonly TASK_TYPES_DIRECT = [{ taskType: 'Other' }];
  // { taskType: 'Existing User-Deactivate profile' }, was  in Task Type direct

  public static readonly TASK_TYPE_DIRECT = { EXISTING_USER_DEACTIVATE_PROFILE: 'Existing User-Deactivate profile', OTHERS: 'Other' };

  public static readonly TASK_STATUS = { PENDING: 'Pending', COMPLETED: 'Completed', CANCELLED: 'Cancelled' };

  public static readonly TASK_STATUSES = [{ status: 'Pending' }, { status: 'Completed' }, { status: 'Cancelled' }];

  public static readonly CUSTOMER_STATUS = {
    NEW: 'New', KYC_DONE: 'KYC', APPROVED: 'Approved', SCHEDULING: 'Scheduling', ACTIVE: 'Active'
  };

  public static readonly HUB_DETAILS_TABS = [{ index: 0, tab: 'allocateDelivery' }, { index: 1, tab: 'manageTask' }, { index: 2, tab: 'stockSummary' }, { index: 3, tab: 'setting' }];

  public static readonly HUB_TABS = { ALLOCATE_DELIVERY: 'allocateDelivery', MANAGE_TASK: 'manageTask', STOCK_SUMMARY: 'stockSummary', SETTING: 'setting' };

  public static readonly USER_TYPE = { CUSTOMER: 'CUSTOMER', ADMIN: 'ADMIN' };

  public static readonly INDENT_STATUS = { PENDING: 'Pending', APPROVED: 'Approved', REJECTED: 'Rejected', COMPLETED: 'Completed' };

  public static readonly LOGO_URL = environment.webServiceEndPointURL + '/digital_asset/downloadFile/milkmor-dark.png?subDir=/logo';

  public static readonly PERMISSION = {
    CAN_VIEW: 'CAN_VIEW',
    CAN_EDIT: 'CAN_EDIT',
    CAN_ADD: 'CAN_ADD',
    CAN_DELETE: 'CAN_DELETE',
    CAN_IMPORT: 'CAN_IMPORT',
    CAN_EXPORT: 'CAN_EXPORT'
  };

  public static readonly MODULES = { DASHBOARD: 'Dashboard', HUB: 'Hub', CWH: 'CWH', TASK: 'Task', PRODUCTS: 'Products', ROLE_PERMISSION: 'Roles & Permissions', CUSTOMER: 'Customers', MASTER: 'Master', ORDERS: 'Orders', FARM: 'Farm', ACCOUNTS: 'Accounts', OTHER: 'Other', OFFERS: 'Offers' };

  public static readonly ACCESS_DENIED_MSG = 'You are not authorized to access this feature.';

  public static readonly OFFER_ORDER_TYPE = [{ CART: 'Cart Orders' }, { CART: 'Subscription Orders' }, { CART: 'Trial Orders' }];
  public static readonly STATIC_LOGO_URL = '/assets/images/milkmor-dark.png';

  public static readonly PRODUCT_CLASSIFICATION = { SALEABLE: 'SALEABLE', NONSALEABLE: 'NONSALEABLE' };

  public static readonly LOCATOR_TYPE = { WAREHOUSE: 'Warehouse', FARM: 'Farm', HUB: 'Hub' };

  public static readonly SUPER_ADMIN = 'SUPER ADMIN';

  public static readonly DEPARTMENTS = { CWH: 'Central Warehouse(CWH)', CUSTOMER_CARE: 'Customer Care', ACCOUNTS: 'Accounts', HUB: 'Hub', FARM: 'Farm', ADMIN: 'ADMIN' };

  public static readonly WAREHOUSE_TYPE = { RTO_FINAL: 'RTO-Final', RTO_INITIAL: 'RTO-Initial', DEFAULT: 'Default' };

  public static readonly STOCK_TRANSFER_REASON = ['Returned', 'Wastage', 'Expired', 'Stolen'];

  public static readonly FREQUENCY_TYPE = { DAILY: 'Daily', ALTERNATE: 'Alternate' };

  public static readonly PAYMENT_TYPE = { OFFLINE: 'Offline', ONLINE: 'Online' };

  public static readonly JOB_TYPE_DELIVERY_PERSON = { DELIVERY_BOY: 'Delivery Boy', DELIVERY_ASSISTANT: 'Delivery Assistant' };

  public static readonly CASH_DATA = [
    { currency: 1, noOfNotes: 0, total: 0 },
    { currency: 2, noOfNotes: 0, total: 0 },
    { currency: 5, noOfNotes: 0, total: 0 },
    { currency: 10, noOfNotes: 0, total: 0 },
    { currency: 20, noOfNotes: 0, total: 0 },
    { currency: 50, noOfNotes: 0, total: 0 },
    { currency: 100, noOfNotes: 0, total: 0 },
    { currency: 200, noOfNotes: 0, total: 0 },
    { currency: 500, noOfNotes: 0, total: 0 },
    { currency: 2000, noOfNotes: 0, total: 0 },
  ];

  public static readonly CASH = { 1: 1, 2: 2, 5: 5, 10: 10, 20: 20, 50: 50, 100: 100, 200: 200, 500: 500, 2000: 2000 };

  public static readonly DELIVERY_ROUTE_HUB_ICONURL = '/assets/images/home-icon.png';
  public static readonly DELIVERY_ROUTE_CUSTOMER_ICONURL;

  public static readonly SCHEDULER_NAMES = { DISABLE_LOCATION: 'DisableLocation', PAUSE_AND_RESUME_SUBSCRIPTION_ORDER: 'PauseAndResumeSubscriptionOrder', SUBSCRIPTION_ORDER_SHARE: 'SubscriptionOrderShare', DELIVERY_BOY_DAILY_ACTIVITY: 'DeliveryBoyDailyActivity', DEACTIVATE_OFFER: 'DeactivateOffer', DELIVERY_BOY_ATTEMPT_SUBSCRIPTION_ORDERS: 'DeliveryBoyAttemptSubscriptionOrders' };

  public static readonly TRANSACTION_TYPE = { CREDIT: 'Credit', DEBIT: 'Debit' };

  public static readonly DELIVERY_LOG_STATUS = { NEW: 'New', ACTIVE: 'Active', DELIVERED: 'Delivered' };

  public static readonly MENUITEMS_TYPES = { LINK: 'link', EXTLINK: 'extLink', EXTTABLINK: 'extTabLink', SUB: 'sub', SUBCHILD: 'subchild', SAPERATOR: 'saperator' };

  public static readonly ACTIVITY_TILL_DATE_DASHBOARD: TillDateActivity[] = [{ activity: 'Paused Order', statistics: 1 }, { activity: 'New Task to Allocate', statistics: 1 }, { activity: 'New Subscription to Allocate', statistics: 1 }, { activity: 'New Cart to Allocate', statistics: 1 }];

  public static readonly ACTIVITIES_TILL_DATE_DASHBOARD = { PAUSED_ORDER: 'Paused Order', NEW_TASK_TO_ALLOCATE: 'New Task to Allocate', NEW_SUBSCRIPTION_TO_ALLOCATE: 'New Subscription to Allocate', NEW_CART_TO_ALLOCATE: 'New Cart to Allocate' };

  public static readonly ACTIVITY_TODAY_DASHBOARD: TodayActivity[] = [{ activity: 'Active Delivery Log', statistics: '1' }, { activity: 'Cash Received / Cash Collection', statistics: '1' }, { activity: 'Subscription Order Delivered / Order planner', statistics: '1' }, { activity: 'Stock Delivered / Stock Allocated', statistics: '1' }, { activity: 'Cart order Delivered / Cart Order assigned', statistics: '1' }, { activity: 'Task Completed / Task Allocated', statistics: '1' }, { activity: 'Order Expires Tommorrow', statistics: '1' }];

  public static readonly TODAYS_ACTIVITIES_DASHBOARD = {
    ACTIVE_DELIVERY_LOG: 'Active Delivery Log', CASH_RECEIVED_CASH_COLLECTION: 'Cash Received / Cash Collection', SUBSCRIPTION_ORDER_DELIVERED_ORDER_PLANNER: 'Subscription Order Delivered / Order planner', STOCK_DELIVERED_STOCK_ALLOCATED: 'Stock Delivered / Stock Allocated',
    CART_ORDER_DELIVERED_CART_ORDER_ASSIGNED: 'Cart order Delivered / Cart Order assigned',
    TASK_COMPLETED_TASK_ALLOCATED: 'Task Completed / Task Allocated',
    ORDER_EXPIRES_TOMMORROW: 'Order Expires Tommorrow'
  };

  public static readonly REFUND_ORDER = { NA: 'NA', PENDING: 'Pending', PAID: 'Paid' };

  public static readonly SMS_FLAG = 'SMS_TRIGGER';
  public static readonly EMAIL_FLAG = 'EMAIL_TRIGGER';

  public static readonly SUBSCRIPTION_ACTION_NAMES = {
    ALLOCATE_HUB: 'Allocate Hub', ALLOCATE_ORDER: 'Allocate Order', PAUSED_ORDER: 'Paused Order', RENEW_ORDER: 'Renew Order',
    RESUME_ORDER: 'Resume Order', CANCEL_ORDER: 'Cancelled Order', EXPIRED_ORDER: 'Expired Order'
  };

  public static readonly EXPORT_CART_ORDER_STATUSES = [{ status: 'Pending' }, { status: 'In Process' }, { status: 'Attempted' }, { status: 'Cancelled' }, { status: 'Delivered' }, { status: 'Returned' }];
}
