import * as $ from 'jquery';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit
} from '@angular/core';
import { MenuItems, BREADSCRUM_LIST } from '../../shared/menu-items/menu-items';
import { AppHeaderComponent } from './header/header.component';
import { AppSidebarComponent } from './sidebar/sidebar.component';

import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UserService } from '../../_services/user.service';
import { EmployeeDetail } from '../../models/UserModel';
/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;
  dir = 'ltr';
  green: boolean;
  blue: boolean;
  dark: boolean;
  minisidebar: boolean;
  boxed: boolean;
  danger: boolean;
  showHide: boolean;
  sidebarOpened;
  name: string;
  menu: Array<any> = [];
  breadcrumbList: Array<any> = [];
  public config: PerfectScrollbarConfigInterface = {};
  private _mobileQueryListener: () => void;
  userData: EmployeeDetail;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private _router: Router,
    private userService: UserService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.menu = BREADSCRUM_LIST;
    this.listenRouting();
    this.getUserDetail();
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {
    // This is for the topbar search
    (<any>$('.srh-btn, .cl-srh-btn')).on('click', function () {
      (<any>$('.app-search')).toggle(200);
    });
    // This is for the megamenu
  }

  //breadscrum
  listenRouting() {
    let routerUrl: string, routerList: Array<any>, target: any;
    this._router.events.subscribe((router: any) => {
      routerUrl = router.urlAfterRedirects;
      if (routerUrl && typeof routerUrl === 'string') {
        if (!routerUrl.includes('/authentication')) {
          target = this.menu;
          this.breadcrumbList.length = 0;
          let urls = routerUrl.split('?');
          if (urls.length > 1) {
            urls[0] = urls[0].concat('?');
          }
          routerList = urls[0].slice(1).split('/');
          routerList.forEach((router, index) => {
            target = target.find(page => page.path.slice(2) === router);
            this.breadcrumbList.push({
              name: target.name,
              path: (index === 0) ? target.path : `${this.breadcrumbList[index - 1].path}/${target.path.slice(2)}`
            });
            if (index + 1 !== routerList.length) {
              target = target.children;
            }
          });
        }
      }
    });
  }

  navigate(path: string) {
    let paths: string[] = path.split('.');
    this._router.navigate([paths[paths.length - 1]]);
  }

  getUserDetail() {
    this.userService.getUserDetails().subscribe(res => {
      if (res['status'] == 200) {
        this.userData = new EmployeeDetail();
        this.userData = res['data'];
      }
    })
  }

}
