import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Response } from "@angular/http";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './user.model';
import { environment } from '../../environments/environment.prod';
import { Router } from '@angular/router';
const webServiceEndPointURL = environment.webServiceEndPointURL;
@Injectable()
export class UserService {
  readonly rootUrl = webServiceEndPointURL;
  constructor(private http: HttpClient,private router:Router) { }

  userAuthentication(userName, password) {
    let authKey: string = this.getBasicAuth("milkmor-client", "milkmor-secret");

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', authKey)
      .set('No-Auth', 'True');

    let body = new HttpParams();
    body = body.set('username', userName+'#ADMIN');
    body = body.set('password', password);
    body = body.set('grant_type', 'password');

    // return this.http.post('/oauth/token', body, { headers: headers });
    return new Promise((resolve, reject) => {
      this.http.post(this.rootUrl+'/oauth/token', body, { headers: headers }).toPromise().then(res => {
        resolve(res);
      },err=>{
        reject(err);
      })
    });

  }


  getuserdetails(authKey: any) {
    const reqHeader = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', 'Bearer ' + authKey)
      .set('No-Auth', 'True');
    //  return this.http.get(this.rootUrl + "/user/details", { headers: reqHeader });
    return new Promise((resolve, reject) => {
      this.http.get(this.rootUrl + "/userlogin/details", { headers: reqHeader }).toPromise().then(res => {
        resolve(res);
      },err=>{
        reject(err);
      })
    });
  }


  getBasicAuth(clientId: string, secretId: string) {
    let credential: string = clientId + ":" + secretId;
    console.log(btoa(credential));
    return "Basic " + btoa(credential);
  }

  Logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }


}
