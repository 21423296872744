import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { UserModel, UnallocateUserDTO } from '../models/UserModel';
const webServiceEndPointURL = environment.webServiceEndPointURL;
@Injectable({
  providedIn: 'root'
})
export class UserService {
  readonly rootUrl = webServiceEndPointURL + '/user';
  constructor(private http: HttpClient) { }

  getActiveUserList() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams()
      .set('order', 'ASC');
    return this.http.get(this.rootUrl, { params: param, headers: headers });
  }

  getAllUserList() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    const param = new HttpParams()
      .set('allRecords', 'true')
      .set('order', 'ASC');
    return this.http.get(this.rootUrl, { params: param, headers: headers });
  }

  getUserDetailsById(id) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/' + id, { headers: headers });
  }

  addUser(user: UserModel) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.post(this.rootUrl, JSON.stringify(user), { headers: headers });
  }

  updateUser(user: UserModel) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.put(this.rootUrl, JSON.stringify(user), { headers: headers });
  }

  deleteUser(id) {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.delete(this.rootUrl + '/' + id, { headers: headers });
  }

  getUnAllocatedUserList(data: UnallocateUserDTO) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('userId', localStorage.getItem('userId'));
    return this.http.put(this.rootUrl + '/unallocated', JSON.stringify(data), { headers: headers });
  }

  getUserName() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/employee/name', { headers: headers });
  }

  getUserType() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/employee/type', { headers: headers });
  }

  getUserDetails() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + '/employee/department', { headers: headers });
  }
}
