import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment.prod";
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from "@angular/common/http";
import { Role } from "../models/RolePermission";

const webServiceEndPointURL = environment.webServiceEndPointURL;
@Injectable({
  providedIn: "root"
})
export class RoleService {
  readonly rootUrl = webServiceEndPointURL + "/role";
  constructor(private http: HttpClient) { }

  getRoleList() {
    const header = new HttpHeaders().set("userId", localStorage.getItem("userId"));
    return this.http.get(this.rootUrl, { headers: header });
  }

  addRole(role: Role) {
    const header = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("userId", localStorage.getItem("userId"));
    return this.http.post(this.rootUrl + "/roleAndPermission", JSON.stringify(role), { headers: header });
  }

  updateRole(role: Role) {
    const header = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("userId", localStorage.getItem("userId"));
    return this.http.put(this.rootUrl + "/roleAndPermission", JSON.stringify(role), { headers: header });
  }

  getRoleDetailsById(id) {
    const header = new HttpHeaders().set("userId", localStorage.getItem("userId"));
    return this.http.get(this.rootUrl + "/" + id, { headers: header });
  }

  getPermissionList() {
    const headers = new HttpHeaders()
      .set('userId', localStorage.getItem('userId'));
    return this.http.get(this.rootUrl + "/addRole", { headers: headers });
  }

  deleteRole(id) {
    const header = new HttpHeaders().set("userId", localStorage.getItem("userId"));
    return this.http.delete(this.rootUrl + "/" + id, { headers: header });
  }

  getRoleAccessControl() {
    const header = new HttpHeaders().set("userId", localStorage.getItem("userId"));
    return this.http.get(this.rootUrl + "AccessRight/permission/map", { headers: header });
  }

  getModuleWiseRoleAccessControl(moduleName: string) {
    const header = new HttpHeaders().set("userId", localStorage.getItem("userId"));
    const param = new HttpParams().set('moduleName', moduleName);
    return this.http.get(this.rootUrl + "AccessRight/permission/map", { headers: header, params: param });
  }
}
