import { Directive, HostListener } from '@angular/core';
@Directive({
  selector: '[appnodblclick]'
})
// appnodblclick
export class NoDblClickDirective {

  constructor() {
  }

  @HostListener('click', ['$event'])
  clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    event.srcElement.parentElement.setAttribute('disabled', true);
    setTimeout(function () {
      event.srcElement.parentElement.removeAttribute('disabled');
    }, 5000);
  }
  @HostListener('submit', ['$event'])
  submitEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    event.srcElement.parentElement.setAttribute('disabled', true);
    setTimeout(function () {
      event.srcElement.parentElement.removeAttribute('disabled');
    }, 5000);
  }

}
