export class UserModel {
  userId: number;
  firstName: string;
  lastName: string;
  homeAddress: string;
  contactNo: number;
  emailAddress: string;
  payScale: number;
  active: boolean;
  password: string;
  departmentEntityId: number;
  departmentId: number;
  designationId: number;
  roleIds: number[] = [];
  roleNameList: string[] = [];
  departmentName: string;
  designationName: string;
  departmentEntityName: string;
}

export class Department {
  departmentId: number;
  departmentName: string;
}

export class Designation {
  designationId: number;
  designationName: string;
  departmentId: number;
}

export class DepartmentDTO {
  departmentId: number;
  departmentName: string;
  designations: Designation[] = [];
}

export class EmployeeDetail {
  departmentName: string;
  designationName: string;
  departmentEntityName: string;
  userName: string;
  roleName: string;
  isSuperAdmin: boolean;
}

export class UnallocateUserDTO {
  warehouseId: number;
  hubId: number;
  farmId: number;
  filterType: string;
}
