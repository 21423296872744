import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { tap, finalize } from "rxjs/operators";
import { MatDialog } from "@angular/material";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private dialog: MatDialog) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('No-Auth') == "True") {
      // this.loaderService.show();
      const clonedreq = req.clone({
        headers: req.headers.delete('No-Auth', 'True')
      });
      return next.handle(clonedreq.clone());
    }

    if (localStorage.getItem('userToken') != null) {
      // this.loaderService.show();
      const clonedreq = req.clone({
        headers: req.headers.set("Authorization", "Bearer " + localStorage.getItem('userToken'))
      });
      return next.handle(clonedreq).pipe(
        tap(succ => { }, err => {
          console.log(JSON.stringify(err));
          if (err.status === 401 || err.status === 0) {
            localStorage.clear();
            this.dialog.closeAll();
            this.router.navigateByUrl('/');
          }
        })
        // ,finalize(() => this.loaderService.hide())
      );
    }
    else {
      this.router.navigateByUrl('/');
    }
  }
}
