export const environment = {
  production: true,
   //webServiceEndPointURL: 'http://milkmor.kodyinfotech.com:8085/milkmor',
	// webServiceEndPointURL: 'https://stageservice.milkmor.com/milkmor',
  // webServiceEndPointURL: 'http://localhost:7011/milkmor',
  mapKey: 'AIzaSyCE26m0xcxRuDD4lfgaQ4aZcHfxkPFDZcM',
  //webServiceEndPointURL: 'http://192.168.1.244:8085/milkmor'
  webServiceEndPointURL: 'https://service.milkmor.com/milkmor'
  //webServiceEndPointURL : 'http://app.milkmor.com:5247/milkmor'

};
