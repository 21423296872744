export class Role {
  rolesId: number;
  roleName: string;
  roleDescription: string;
  userId: number;
  active = true;
  createdByName: string;
  updatedByName: string;
  permissions: Permission[] = [];
}

export class Permission {
  all = false;
  rolePermissionId: number;
  roleId: number;
  moduleId: number;
  moduleName: string;
  canView = false;
  canEdit = false;
  canAdd = false;
  canDelete = false;
  canImport = false;
  canExport = false;
  active = true;
  isDisable = false;
}

export class ModuleWisePermission {
  CAN_VIEW: boolean;
  CAN_EDIT: boolean;
  CAN_ADD: boolean;
  CAN_DELETE: boolean;
  CAN_IMPORT: boolean;
  CAN_EXPORT: boolean;
}
