import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadSpinnerService {
  public isLoading = new BehaviorSubject(false);
  constructor(){
    this.hide();
  }
    show() {
        this.isLoading.next(true);
    }
    hide() {
        this.isLoading.next(false);
    }

}
