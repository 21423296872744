import { MilkmorConstants } from "./../../../shared/MilkmorConstants";
import { Menu } from "./../../../shared/menu-items/menu-items";
import {
  ChangeDetectorRef,
  Component,
  NgZone,
  OnDestroy,
  ViewChild,
  HostListener,
  Directive,
  AfterViewInit
} from "@angular/core";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { MediaMatcher } from "@angular/cdk/layout";
import { MenuItems } from "../../../shared/menu-items/menu-items";
import { RoleService } from "../../../_services/role.service";
import * as _ from "lodash";
import { Router } from "@angular/router";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: []
})
export class AppSidebarComponent implements OnDestroy {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  sideBarMenus: Menu[];
  accessControl: Map<string, Map<string, boolean>> = new Map();
  sideBarModuleList: string[] = [];
  sidebarMenu: Menu[] = [];
  menuTypes = MilkmorConstants.MENUITEMS_TYPES;

  private _mobileQueryListener: () => void;
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  subclickEvent() {
    this.status = true;
  }
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private roleService: RoleService, private router: Router
  ) {
    this.sideBarMenus = menuItems.getMenuitem();
    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.roleAccessControl();
  }

  roleAccessControl() {
    this.roleService.getRoleAccessControl().subscribe(res => {
      this.accessControl = res["data"];
      let that = this;
      _.forEach(this.accessControl, function (value, key) {
        _.forEach(value, function (values, keys) {
          if (
            keys.toLowerCase() ==
            MilkmorConstants.PERMISSION.CAN_VIEW.toLowerCase() &&
            values
          ) {
            that.sideBarModuleList.push(key);
          }
        });
      });
      this.sideBarModuleList.forEach(modules => {
        this.menuItems.getMenuitem().forEach(menu => {
          if (menu.name.toLowerCase() == modules.toLowerCase()) {
            this.sidebarMenu.push(menu);
          }
        });
      });
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  navigationForSubChild(main, sub, metaSub) {
    localStorage.removeItem('cartStatus');
    localStorage.removeItem('currentPageCart');
    localStorage.removeItem('subStatus');
    localStorage.removeItem('currentPageSub');
    // this.router.navigate(['/' + main + '/' + sub + '/' + metaSub]);
  }
  navigationForSub(main, sub) {
    localStorage.removeItem('cartStatus');
    localStorage.removeItem('currentPageCart');
    localStorage.removeItem('subStatus');
    localStorage.removeItem('currentPageSub');
    // this.router.navigate(['/' + main + '/' + sub]);
  }
  navigationForMain(main) {
    localStorage.removeItem('cartStatus');
    localStorage.removeItem('currentPageCart');
    localStorage.removeItem('subStatus');
    localStorage.removeItem('currentPageSub');
    // this.router.navigate(['/' + main]);
  }
}
