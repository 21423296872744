import { DetailHubComponent } from './hub/list-hub/detail-hub/detail-hub.component';
import { CreateHubComponent } from './hub/list-hub/create-hub/create-hub.component';
import { ListHubComponent } from './hub/list-hub/list-hub.component';
import { HubComponent } from './hub/hub.component';
import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboards.module#DashboardsModule'
      },

      {
        path: 'master',
        loadChildren:
          './master/master.module#MasterModule'
      },
      {
        path: 'farm',
        loadChildren:
          './farm/farm.module#FarmModule'
      },
      {
        path: 'central-warehouse',
        loadChildren:
          './central-warehouse/central-warehouse.module#WarehouseModule'
      },
      {
        path: 'subscription',
        loadChildren:
          './subscription/subscription.module#SubscriptionModule'
      },
      {
        path: 'product',
        loadChildren:
          './products/product.module#ProductModule'
      },
      {
        path: 'hub',
        loadChildren: './hub/hub.module#HubModule'
      },
      {
        path: 'customers',
        loadChildren: './customers/customers.module#CustomersModule'
      },
      {
        path: 'orders',
        loadChildren:
          './orders/orders.module#OrderModule'
      },
      {
        path: 'rolesPermission',
        loadChildren:
          './roles-permission/roles-permission.module#RolesPermissionModule'
      },
      {
        path: 'inventory',
        loadChildren:
          './inventory/inventory.module#InventoryModule'
      },
      {
        path: 'accounts',
        loadChildren:
          './accounts/accounts.module#AccountsModule'
      },
      {
        path: 'others',
        loadChildren:
          './others/others.module#OthersModule'
      },
      {
        path: 'task-allocation',
        loadChildren:
          './task-allocation/task-allocation.module#TaskAllocationModule'
      },
      {
        path: 'settings',
        loadChildren:
          './settings/settings.module#SettingsModule'
      },
      {
        path: 'offers',
        loadChildren:
          './offers/offers.module#OffersModule'
      },
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
