import { MilkmorConstants } from './../../../shared/MilkmorConstants';
import { ModuleWisePermission } from './../../../models/RolePermission';
import { RoleService } from './../../../_services/role.service';
import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { UserService } from '../../../_services/user.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  permissions: ModuleWisePermission = new ModuleWisePermission();
  employeeName: string;
  constructor(private router: Router, private roleService: RoleService, private userService: UserService) {
    this.getUserName();
    this.getRoleAccessControl();
  }

  getRoleAccessControl() {
    let moduleName = MilkmorConstants.MODULES.TASK;
    this.roleService.getModuleWiseRoleAccessControl(moduleName).subscribe(res => {
      this.permissions = res['data'].Task;
      if (!this.permissions.CAN_VIEW) {
        $("#taskAllocateBtn").remove();
      }
    })
  }

  getUserName() {
    this.userService.getUserName().subscribe(res => {
      if (res['data']) {
        this.employeeName = res['data'];
        $("#taskAllocateBtn").remove();
      } else {
        this.employeeName = MilkmorConstants.SUPER_ADMIN;
      }
    })
  }
  navigateToTask() {
    this.router.navigate(['/task-allocation']);
  }

  navigateToSettings() {
    this.router.navigate(['/settings']);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  public config: PerfectScrollbarConfigInterface = {};

}
